import React, { useContext, useEffect } from 'react';
import { Context } from '../../../reducer/Store';

const coeff = 25;

const colors = [
  { level: 0, color: 'white' },
  { level: 1, color: 'white' },
  { level: 2, color: 'black' },
  { level: 3, color: 'black' },
  { level: 4, color: '#5AB6FF' },
  { level: 5, color: '#5AB6FF' },
  { level: 6, color: '#FF4E3F' },
  { level: 7, color: '#FF4E3F' },
  { level: 8, color: '#FEFE5B' },
  { level: 9, color: '#FEFE5B' },
];

const Cible = ({ level, width }) => {
  const [state, dispatch] = useContext(Context);

  return (
    <div
      style={{ borderRadius: '50%' }}
      onClick={async (e) => {
        dispatch({ type: 'SET_SCORE', payload: 0 });
        dispatch({ type: 'SET_POINTER', payload: e });
      }}
    >
      <div
        style={{
          margin: `${level === 0 ? 'auto' : '0px'}`,
          zIndex: level,
          width: `${width}px`,
          height: `${width}px`,
          borderRadius: '50%',
          border: `1px solid ${level === 3 ? 'white' : 'black'}`,
          textAlign: 'center',
          position: 'relative',
          top: level === 0 ? 0 : `${coeff}px`,
          left: level === 0 ? 0 : `${coeff}px`,
          backgroundColor: colors.find((color) => {
            return color.level === level;
          }).color,
        }}
        onClick={async (e) => {
          dispatch({ type: 'SET_SCORE', payload: 0 });
          dispatch({ type: 'SET_POINTER', payload: e });
          dispatch({ type: 'SET_SCORE', payload: level + 1 });
        }}
      >
        {level === 0 ? (
          <div style={{ fontSize: '72px', position: 'absolute', top: '-25px', right: '-50px' }}>
            {state.started ? (state.score !== 10 ? state.score : 'M') : ''}
          </div>
        ) : (
          ''
        )}
        {level !== 9 ? <Cible width={width - coeff * 2} level={level + 1} /> : ''}
      </div>
    </div>
  );
};

export default Cible;
