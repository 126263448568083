import React from 'react';
import cn from 'classnames';
import styles from './HeadOptions.module.sass';

const HeadOptions = ({ className, serie }) => {
  const discipline = JSON.parse(localStorage.getItem('discipline'));
  const competition = JSON.parse(localStorage.getItem('competition'));

  return (
    <div className={cn(className, styles.head)}>
      <div className={styles.preview}>
        <img src={`/images/competition/stq.png`} alt="Competition" />
      </div>
      <div className={styles.title}>Série {serie} </div>
      <div className={styles.author}>
        <div className={styles.avatar}>
          <img src={`/images/icon/${discipline.slug}.svg`} alt="Avatar" />
        </div>
        <div className={styles.man}>{discipline.name}</div>
      </div>
    </div>
  );
};

export default HeadOptions;
