import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../reducer/Store';
import Caller from '../../service/caller';
import config from '../../config';

const Abs = ({ backgroundColor, color, loading, position, content, competition, poste }) => {
  const [state, dispatch] = useContext(Context);

  return (
    <div
      onClick={async () => {
        await Caller.request(
          'PUT',
          `${config.api_path}/competition/licensee/${competition._id}`,
          {
            licensee: poste.id,
            status: content,
          },
          {
            'x-access-token': localStorage.getItem('arbitre-token'),
          },
        );
        window.location.href = '/poste-tireur';
      }}
      style={{
        opacity: loading ? '0.3' : '1',
        backgroundColor: backgroundColor,
        color: color || 'black',
        padding: '10px',
        margin: '2px',
        textAlign: 'center',
        width: position === 'right' ? '95%' : '100%',
        border: '1px solid black',
      }}
    >
      {content}
    </div>
  );
};

export default Abs;
