import Icon from '../../components/Icon';

const TableSession = ({session, sessionNumber, config, setIncident, setVisible}) => {
  let line = 1
  let pad = 0
  const distance = config.distances.length > 0
    ? config.distances[sessionNumber -1]
    : null

  const renderHeaders = () => (
    <thead>
      <tr>
        <th colSpan={11} style={{ textAlign: 'center' }}>
          Session {sessionNumber} {distance  && `(${distance}m)`}
        </th>
      </tr>
      <tr>
        <th style={{
          paddingLeft: '12px',
          paddingRight: '12px',
          paddingTop: '5px',
          paddingbottom: '5px',
          color: 'white',
          backgroundColor: 'white',
        }}>&nbsp;</th>

        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
          return (
            <th style={{
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '5px',
              paddingbottom: '5px',
              textAlign: 'center',
              color: 'white',
              backgroundColor: 'navy',
            }}>
              {index}
            </th>
          )
        })}
      </tr>
    </thead>
  )

  return (
    <table style={{
      width: '65%',
      float: 'left' ,
      marginTop: '20px',
      borderCollapse: 'separate',
      borderSpacing: '0px 2px',
      tableLayout: 'fixed'
    }}>
      {renderHeaders()}

      <tbody>
        {session.map((arrows, i) => {
          return (
            <tr>
              <td style={{
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingTop: '5px',
                paddingbottom: '5px',
                textAlign: 'center',
                color: 'white',
                backgroundColor: 'navy',
              }}>{line && line++}</td>

              {arrows.arrows.map((arrow, a) => {
                pad++;
                pad = pad > 6 ? 1 : pad
                return (
                  <td style={{
                    paddingTop: '5px',
                    paddingbottom: '5px',
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: pad <= 3 ? 'lightgrey' : 'white'
                  }}>
                    {arrow.score !== null ? (
                      arrow.score === -1 ? (
                        <div style={{ height: '100%' }}>
                          <div style={{
                            margin: 'auto',
                            backgroundColor: 'red',
                            color: 'white',
                            marginTop: '-15px',
                            padding: '1px',
                            width: '25px',
                            height: '25px',
                            borderRadius: '50%',
                            fill: 'white',
                            cursor: 'pointer',
                            textAlign: 'center',
                          }}>
                            <Icon onClick={() => {
                              setIncident({ volley: arrow.volley, arrow: arrow.number })
                              setVisible(true)
                            }}
                            style={{ padding: '3px' }}
                            name={'flag'}
                            size={12}/>{' '}
                          </div>
                        </div>
                      ) : arrow.score === -2 ? (
                        <span style={{ textAlign: 'center', fontWeight: 'bold' }}>CM</span>
                      ) : arrow.bullseye ? (
                        <span style={{ fontWeight: 'bold' }}>M</span>
                      ) : (
                        arrow.score
                      )
                    ) : (
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={11} style={{ textAlign: 'right', fontWeight: 'bold' }}>
            Sous-total :
          </td>
        </tr>
        <tr>
          <td colSpan={11} style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '7px', color: 'red' }}>
            Total Général :
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default TableSession
