import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../reducer/Store';
import Caller from '../../service/caller';
import config from '../../config';
import styles from './Poste.module.sass';
import Loader from '../../components/Loader';

const Button = ({
  backgroundColor,
  color,
  content,
  competition,
  volee,
  arrow,
  poste,
  setData,
  setLoading,
  colspan,
  loading,
  value,
  position,
}) => {
  const [state, dispatch] = useContext(Context);

  return (
    <div
      onClick={async (e) => {
        setLoading(true);
        const result = await Caller.request(
          'put',
          `${config.api_path}/competition/score/rewind/${competition._id}`,
          {
            licensee: poste.id,
          },
          {
            'x-access-token': localStorage.getItem('arbitre-token'),
          },
        );
        setLoading(false);
        setData(result.data.data);
      }}
      style={{
        backgroundColor: backgroundColor,
        color: color || 'black',
        padding: '10px',
        margin: '2px',
        textAlign: 'center',
        width: position === 'right' ? '95%' : '100%',
        border: '1px solid black',
      }}
    >
      {content}
    </div>
  );
};

export default Button;
