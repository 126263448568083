import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Poste.module.sass';
import stylesRecap from './Poste-recap.module.sass';
import Control from '../../components/Control';
import Validation from './components/Validation';

import { Context } from '../../reducer/Store';
import { useParams } from 'react-router-dom';
import Icon from '../../components/Icon';
import Caller from '../../service/caller';
import config from '../../config';
import moment from 'moment';

const breadcrumbs = [
  {
    title: 'Discipline',
  },
  {
    title: 'Compétition',
  },
  {
    title: 'Série',
  },
  {
    title: 'Arbitre',
  },
  {
    title: 'Arbitre assigné',
  },
  {
    title: 'Séléction poste de tir',
  },
];

const Score = () => {
  const [state, dispatch] = useContext(Context);
  const [data, setData] = useState([]);

  const { volee, validate } = useParams();

  const poste = JSON.parse(localStorage.getItem('poste'));
  const competition = JSON.parse(localStorage.getItem('competition'));
  const serie = localStorage.getItem('serie');
  let disabled = 'disabled';

  useEffect(async () => {
    const result = await Caller.request(
      'GET',
      `${config.api_path}/competition/score/${competition._id}/${poste.id}`,
      {},
      {
        'x-access-token': localStorage.getItem('arbitre-token'),
      },
    );
    setData(result.data.data);
  }, []);
  let acc = 0;
  let played = 0;
  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.wrapper}>
          <div className={cn(styles.confirm, styles.confirm)} style={{ width: '100%' }}>
            <div className={stylesRecap.head}>
              <div className={cn('h4', stylesRecap.title)}>
                Poste {poste.position}
                {poste.grade} - {poste.firstname} {poste.lastname}
              </div>
              <div style={{ marginTop: '0px', padding: '10px' }} className={styles.info}>
                <div style={{ float: 'left', padding: '10px' }}>
                  <Icon name={'serie'} size={24} />
                  <span style={{ marginLeft: '5px' }}>Série {serie}</span>
                </div>
                <div style={{ float: 'left', padding: '10px' }}>
                  <Icon name={'calendar-grey'} size={24} />
                  <span style={{ marginLeft: '5px' }}>{moment(competition.from).format('DD/MM/YYYY')}</span>
                </div>
                <div style={{ float: 'left', padding: '10px' }}>
                  <Icon name={'cible'} size={24} />
                  <span style={{ marginLeft: '5px' }}>{competition.type}</span>
                </div>
                <div style={{ float: 'left', padding: '10px' }}>
                  <Icon name={'cup'} size={24} />
                  <span style={{ marginLeft: '5px' }}>{competition.name}</span>
                </div>
                <div style={{ float: 'left', padding: '10px' }}>
                  <Icon name={'location-grey'} size={24} />
                  <span style={{ marginLeft: '5px' }}>{competition.location}</span>
                </div>
              </div>
            </div>
            <div className={stylesRecap.head}>
              {data.map((cible) => {
                cible.map((c) => {
                  acc += parseInt(c.volley.length);
                  c.volley.map((volley) => {
                    played += volley.score !== null ? 1 : 0;
                  });
                });
              })}
              {played === acc ? (
                <div className={stylesRecap.table}>
                  <div style={{ width: '50%' }} className={stylesRecap.row}>
                    <table style={{ borderRadius: '50%' }}>
                      <tr style={{ backgroundColor: 'lightgray' }}>
                        <td>&nbsp;</td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>Total</td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>M</td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>9</td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>Coups</td>
                      </tr>

                      <tr style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>
                        <td style={{ textAlign: 'center' }}>{competition.type} Total</td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>
                          {data.reduce((total, cible) => {
                            return (
                              total +
                              cible.reduce((total, c) => {
                                return (
                                  total +
                                  c.volley.reduce((total, volley) => {
                                    return total + volley.score;
                                  }, 0)
                                );
                              }, 0)
                            );
                          }, 0)}
                        </td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>
                          {data.reduce((total, cible) => {
                            return (
                              total +
                              cible.reduce((total, c) => {
                                return (
                                  total +
                                  c.volley.reduce((total, volley) => {
                                    return total + (volley.score === 10 ? 1 : 0);
                                  }, 0)
                                );
                              }, 0)
                            );
                          }, 0)}
                        </td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>
                          {data.reduce((total, cible) => {
                            return (
                              total +
                              cible.reduce((total, c) => {
                                return (
                                  total +
                                  c.volley.reduce((total, volley) => {
                                    return total + (volley.score === 9 ? 1 : 0);
                                  }, 0)
                                );
                              }, 0)
                            );
                          }, 0)}
                        </td>
                        <td style={{ padding: '20px', textAlign: 'center' }}>
                          {data.reduce((total, cible) => {
                            return (
                              total +
                              cible.reduce((total, c) => {
                                return (
                                  total +
                                  c.volley.reduce((total, volley) => {
                                    return total + (volley.score === null ? 1 : 0);
                                  }, 0)
                                );
                              }, 0)
                            );
                          }, 0)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
            {data?.map((scoreTable, index) => {
              {
                return (
                  <div className={stylesRecap.table}>
                    <div style={{ width: '70%', float: 'left' }} className={stylesRecap.row}>
                      <table
                        style={{
                          overlow: 'hidden',
                          borderCollapse: 'collapse',
                          borderRadius: '1em',
                        }}
                      >
                        <thead>
                          <tr style={{ backgroundColor: '#003A8E', color: 'white', padding: '5px' }}>
                            <td style={{ width: '10%', padding: '10px' }}>Volées</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>1</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>2</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>3</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>4</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>5</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>6</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>7</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>8</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>9</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>10</td>
                            <td style={{ width: '3%', textAlign: 'center' }}>&nbsp;</td>
                            <td style={{ width: '7%', textAlign: 'center' }}>Total</td>
                            <td style={{ width: '7%', textAlign: 'center' }}>M</td>
                            <td style={{ width: '7%', textAlign: 'center' }}>9</td>
                            <td style={{ width: '10%', padding: '10px', textAlign: 'center' }}>Coups</td>
                          </tr>
                        </thead>
                        <tbody>
                          {scoreTable?.map((score, index) => {
                            return (
                              <tr
                                key={`tr-${index}`}
                                style={{ fontSize: '12px', backgroundColor: index % 2 === 0 ? '#E6E8EC' : 'white' }}
                              >
                                <td style={{ padding: '10px' }}>{index + 1}</td>
                                {score?.volley.map((volee) => {
                                  return (
                                    <td style={{ textAlign: 'center' }}>
                                      {volee.score >= 0 ? (
                                        <div
                                          style={
                                            volee.incident
                                              ? {
                                                  margin: 'auto',
                                                  width: '25px',
                                                  height: '25px',
                                                  color: 'white',
                                                  borderRadius: '50%',
                                                  padding: '3px',
                                                  textAlign: 'center',
                                                  backgroundColor: 'green',
                                                }
                                              : {}
                                          }
                                        >
                                          {volee.score}
                                        </div>
                                      ) : (
                                        (
                                          <div style={{ height: '100%' }}>
                                            <div
                                              style={{
                                                margin: 'auto',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                padding: '3px',
                                                width: '25px',
                                                height: '25px',
                                                borderRadius: '50%',
                                                fill: 'white',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                              }}
                                            >
                                              <Icon style={{ padding: '3px' }} name={'flag'} size={12} />
                                            </div>
                                          </div>
                                        ) || 0
                                      )}
                                    </td>
                                  );
                                })}
                                <td></td>
                                <td style={{ textAlign: 'center' }}>
                                  {score?.volley.reduce((total, item) => {
                                    return parseInt(total) + parseInt(item.score || 0);
                                  }, 0)}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {score?.volley.reduce((total, item) => {
                                    return total + (item.score == 10 ? 1 : 0);
                                  }, 0)}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {score?.volley.reduce((total, item) => {
                                    return total + (item.score == 9 ? 1 : 0);
                                  }, 0)}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {score?.volley.reduce((total, item) => {
                                    return total + (item.score !== null ? 1 : 0);
                                  }, 0)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr style={{ backgroundColor: '#003A8E', color: 'white', padding: '5px' }}>
                            <td style={{ width: '10%', padding: '10px' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ width: '7%' }}></td>
                            <td style={{ padding: '10px' }}>{competition.type}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    {played !== acc ? (
                      <div style={{ marginLeft: '30px', width: '25%', float: 'left' }}>
                        <a href={'/cible/1/' + volee}>
                          <img src={'/images/cible.png'} style={{ width: '100%' }} />
                        </a>
                        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>
                          Cliquez sur une cible pour l'afficher
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>

        <Validation competition={competition} poste={poste} volee={volee} validate={validate === 'validate'} />
      </div>
    </div>
  );
};

export default Score;
