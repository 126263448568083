import React from 'react';
import cn from 'classnames';
import styles from './Tireur.module.sass';
import Icon from '../Icon';
import Caller from '../../service/caller';
import config from '../../config';

class Tireur extends React.Component {
  state = { validate: false, background: '#3B71FE', color: 'white' };
  render() {
    return (
      <div>
        <div>
          <span
            style={{
              fontSize: '26px',
              fontWeight: 'bold',
              display: 'block',
              textAlign: 'center',
              paddingTop: '5px',
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              float: 'left',
            }}
          >
            {this.props.item.position}
          </span>
          <span style={{ float: 'left', padding: '10px', fontSize: '2 0px' }}>
            <strong>
              {this.props.item.firstname} {this.props.item.lastname}
            </strong>
            <br />
            <span style={{ fontSize: '15px', fontWeight: 'normal' }}>
              <Icon name="categorie" size="10" />
              Catégorie {this.props.item.category}
            </span>
          </span>
        </div>
        <div style={{ clear: 'both' }}></div>
        <div style={{ marginTop: '10px', padding: '20px', backgroundColor: '#F4F5F6' }} className={styles.title}>
          <span style={{ fontSize: '15px', fontWeight: 'normal' }}>
            <Icon name="dossard" size="24" />
            <div style={{ float: 'left', marginLeft: '5px' }}>
              Dossard
              <br />
              <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{this.props.item.bib}</span>
            </div>
          </span>
          <div style={{ clear: 'both' }}></div>
        </div>
        <div className={styles.note} style={{ marginTop: '30px' }}>
          <a
            style={{ border: '1px solid #F4F5F6', width: '40%', backgroundColor: 'white', color: 'black' }}
            className={cn('button button-small', styles.button)}
          >
            Transférer
          </a>
          <a
            onClick={async (event) => {
              this.setState({ validate: true, background: 'white', color: 'black' });
              await Caller.request(
                'PUT',
                `${config.api_path}/competition/poste/${this.props.competition._id}/${this.props.serie}`,
                { licensee: this.props.item.id },
                {
                  'x-access-token': localStorage.getItem('arbitre-token'),
                },
              );
            }}
            style={{ width: '40%', backgroundColor: this.state.background, color: this.state.color }}
            className={cn('button button-small', styles.button)}
          >
            {this.state.validate}
            Valider
            {this.state.validate ? (
              <div
                style={{
                  marginLeft: '10px',
                  textAlign: 'center',
                  backgroundColor: '#58C27D',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                }}
              >
                <div style={{ marginLeft: '-8px', marginTop: '5px' }}>
                  <Icon style={{ margin: '0px !important' }} size={12} name={'check'} />
                </div>
              </div>
            ) : (
              ''
            )}
          </a>
        </div>
      </div>
    );
  }
}
export default Tireur;
