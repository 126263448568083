import React, { useContext, useEffect, useState } from 'react';
import Tireurs from '../../components/Tireurs';

// data
import { Context } from '../../reducer/Store';
import Caller from '../../service/caller';
import config from '../../config';

const Tireur = () => {
  const [data, setData] = useState([]);

  const competition = JSON.parse(localStorage.getItem('competition'));
  const serie = localStorage.getItem('serie');
  const pin = localStorage.getItem('pin');

  useEffect(async () => {
    if (data.length === 0) {
      const response = await Caller.requestAdmin('POST', `${config.api_path}/auth/sign-in/referee`, {
        competition: competition._id,
        pin: pin,
      });

      localStorage.setItem('arbitre-token', response?.data?.data?.token);

      Caller.request(
        'GET',
        `${config.api_path}/competition/licensee/${competition._id}/${serie}`,
        {},
        {
          'x-access-token': localStorage.getItem('arbitre-token'),
        },
      ).then((result) => {
        setData(result.data.data);
      });
    }
  });

  return (
    <>
      <Tireurs competition={competition} items={data} serie={serie} />
    </>
  );
};

export default Tireur;
