import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import styles from './Abritre.module.sass';
import Control from '../../components/Control';
import ConfirmAndPay from '../../components/ConfirmAndPay';
import PriceDetails from '../../components/PriceDetails';
import { Context } from '../../reducer/Store';
import { useParams } from 'react-router-dom';
import Caller from '../../service/caller';
import config from '../../config';

const breadcrumbs = [];

const Arbitre = () => {
  const [state, dispatch] = useContext(Context);
  let { serie } = useParams();

  useEffect(async () => {
    const result = await Caller.requestAdmin('GET', `${config.api_path}/serie/${serie}`);

    localStorage.setItem('serie', JSON.stringify(result.data));
    dispatch({ type: 'SET_SERIE', payload: result.data });
  }, []);

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.wrapper}>
          <ConfirmAndPay className={styles.confirm} />
          <PriceDetails serie={serie} className={styles.price} more />
        </div>
      </div>
    </div>
  );
};

export default Arbitre;
