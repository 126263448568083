import React, { useEffect, useState } from 'react'
import Caller from '../../service/caller'
import config from '../../config'
import cn from 'classnames'
import styles from './Poste.module.sass'
import stylesRecap from './Poste-recap.module.sass'
import moment from 'moment'
import Points from './points'
import { Link, useHistory } from 'react-router-dom'
import Icon from '../../components/Icon'
import Modal from '../../components/Modal'
import TableSummary from './tableSummary'
import TableSession from './tableArrows'
import TableOverview from './tableOverview'

const Scoring = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [incident, setIncident] = useState(null)
  const [visible, setVisible] = useState(false)
  const navigate = useHistory()
  const poste = JSON.parse(localStorage.getItem('poste'))
  const competition = JSON.parse(localStorage.getItem('competition'))
  const serie = localStorage.getItem('serie')
  let showOverview = true

  useEffect(() => {
    Caller.request(
      'GET',
      `${config.api_path}/competition/score/${competition._id}/${poste.id}`,
      {},
      {
        'x-access-token': localStorage.getItem('arbitre-token'),
      },
    ).then(result => {
      setData(result.data.data)
      setFinish(result.data.data?.state?.arrows?.validated === result.data.data?.config?.arrows)
      setIsLoading(false)
    })
  }, [isLoading])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.wrapper}>
          <div className={cn(styles.confirm, styles.confirm)} style={{ width: '100%', marginTop: '0px', paddingRight: 0 }}>
            <div className={stylesRecap.head}  style={{ textAlign: 'right'}}>
              <Link className={cn('button-stroke button-small', styles.button)} to={'/poste-tireur'} >
                <Icon name="arrow-left" size="10" />
                <span>Liste tireurs</span>
              </Link>
              <div style={{ textAlign: 'center', marginTop: '0px' }} className={cn('h4', stylesRecap.title)}>
                Competition {competition.name}
                <br/>

                <span style={{ fontSize: '22px' }} className={styles.info}>
                  Du {moment(competition.from).format('DD')} au {moment(competition.to).format('DD/MM/YYYY')}
                </span>
              </div>
              <div style={{ fontSize: '22px' }} className={styles.info}>
                <div style={{ width: '50%', float: 'left' }}>
                  <table style={{ fontSize: '16px', marginTop: '40px' }}>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Tireur : </td>
                        <td>
                          {poste.firstname} {poste.lastname}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Série : </td>
                        <td>{serie}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Poste : </td>
                        <td style={{fontWeight: 'bold', color: 'red'}}>{poste.position}{poste.grade}</td>
                      </tr>

                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Dossard : </td>
                        <td style={{fontWeight: 'bold', color: 'red'}}>{poste.bib}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ width: '50%', float: 'left' }}>
                  <table style={{ fontSize: '16px', marginTop: '40px' }}>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Volée : </td>
                        <td>{data?.state?.volley}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bold' }}>Total : </td>
                        <td>
                          {data?.state?.arrows?.done}/{data?.config?.arrows}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {finish && (
          <div style={{ fontSize: '22px', color: 'green', width: '100%', padding: '30px', textAlign: 'center' }}>
            Cette séssion est terminée
          </div>
        )}

        <div style={{ paddingTop: '30px', textAlign: 'left' }}>
          <Points
            finish={finish}
            data={data}
            competition={competition}
            setData={setData}
            setLoading={setLoading}
            loading={loading}
            poste={poste}
            setVisible={setVisible}
          />

          <div style={{ width: '65%', textAlign: 'left', float: 'left', paddingLeft: '20px' }}>
          {data?.sessions?.map((session, j) => {
            if (j + 1 < data.state.current_session && showOverview) {
              showOverview = false
              return (
                <TableOverview
                  sessions={data.sessions.filter((el, ind) => ind + 1 < data.state.current_session)}
                  competition={competition}
                  config={data.config}
                />
              )
            } else if (j + 1 === data.state.current_session) {
              return (
                <>
                  <TableSession
                    session={session}
                    sessionNumber={j+1}
                    config={data.config}
                    setIncident={setIncident}
                    setVisible={setVisible}
                  />

                  <TableSummary
                    session={session}
                    state={data.state}
                    sessionNumber={j+1}
                    config={data.config}
                    competition={competition}
                  />
                </>
              )
            } else {
              return ('')
            }
          })}
          </div>
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{ marginTop: '-40px' }}>
          <Points
            finish={finish}
            data={data}
            competition={competition}
            setData={setData}
            setLoading={setLoading}
            loading={loading}
            poste={poste}
            incidentStyle={true}
            incident={incident}
            setVisible={setVisible}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Scoring
