import React from 'react';
import cn from 'classnames';
import styles from './Item.module.sass';
import moment from 'moment';

const Item = ({ discipline, competition, item, className }) => {
  return (
    <div
      className={cn(styles.item, className)}
      onClick={() => {
        localStorage.setItem('serie', item);
        window.location.href = `/arbitre/${item}`;
      }}
    >
      <div className={cn('status-gray', styles.number)}>Série {item}</div>
      <div className={styles.preview}>
        <img
          style={{ height: '50px', width: '50px' }}
          rc="/images/icon/arbalette.svg"
          src={`/images/icon/${discipline.slug}.svg`}
          alt={`Icon ${discipline.slug}`}
        />
      </div>
      <div className={styles.title}>{competition.name}</div>
      <div className={styles.time}>{competition.place}</div>
      <div className={styles.time}>
        {moment(competition.from).format('DD/MM/YYYY')}
      </div>
    </div>
  );
};

export default Item;
