import React, { useState } from 'react';
import cn from 'classnames';
import styles from './PriceDetails.module.sass';
import HeadOptions from './HeadOptions';
import Icon from '../Icon';
import moment from 'moment';

const PriceDetails = ({ className, serie }) => {
  const handleSubmit = (e) => {
    alert();
  };

  const competition = JSON.parse(localStorage.getItem('competition'));

  return (
    <div className={cn(className, styles.price)}>
      <HeadOptions className={styles.head} serie={serie} />
      <div
        className={cn(styles.description, {
          [styles.flex]: true,
        })}
      >
        <div className={styles.item} key={1}>
          <div className={styles.icon}>
            <Icon name={'calendar'} size="24" />
          </div>
          <div className={styles.box}>
            <div className={styles.category}>date et horaire</div>
            <div className={styles.subtitle}>{moment(competition.from).format('DD/MM/YYYY')}</div>
          </div>
        </div>
        <div className={styles.item} key={2}>
          <div className={styles.icon}>
            <Icon name={'cup'} size="24" />
          </div>
          <div className={styles.box}>
            <div className={styles.category}>Compétition</div>
            <div className={styles.subtitle}>{competition.name}</div>
          </div>
        </div>
        <div className={styles.item} key={3}>
          <div className={styles.icon}>
            <Icon name={'cible'} size="24" />
          </div>
          <div className={styles.box}>
            <div className={styles.category}>{competition.type}</div>
            <div className={styles.subtitle}>{competition.name}</div>
          </div>
        </div>
        <div className={styles.item} key={4}>
          <div className={styles.icon}>
            <Icon name={'location'} size="24" />
          </div>
          <div className={styles.box}>
            <div className={styles.category}>Localisation</div>
            <div className={styles.subtitle}>{competition.location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;
