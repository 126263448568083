import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../reducer/Store';
import Caller from '../../service/caller';
import config from '../../config';

const Button = ({
  backgroundColor,
  color,
  content,
  competition,
  volee,
  arrow,
  poste,
  setData,
  setLoading,
  loading,
  value,
  position,
  setVisible,
  incident,
  bullseye
}) => {
  const [state, dispatch] = useContext(Context);

  return (
    <div
      onClick={async (e) => {
        if (loading) {
          return false;
        }
        setLoading(true);
        const result = await Caller.request(
          'post',
          `${config.api_path}/competition/score/${competition._id}`,
          {
            licensee: poste.id,
            volley: volee,
            arrow: arrow,
            score: value || content,
            bullseye: bullseye === true
          },
          {
            'x-access-token': localStorage.getItem('arbitre-token'),
          },
        );
        if (arrow !== 3) {
          setLoading(false);
        }
        setData(result.data.data);
        setVisible(false);
      }}
      style={{
        opacity: loading ? '0.3' : '1',
        backgroundColor: backgroundColor,
        color: color || 'black',
        padding: '10px',
        margin: '2px',
        textAlign: 'center',
        width: position === 'right' ? '95%' : '100%',
        border: '1px solid black',
      }}
    >
      {content}
    </div>
  );
};

export default Button;
