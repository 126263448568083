import React, { useEffect, useState } from 'react';
import Tireurs from '../../components/PosteTireurs';

// data
import Caller from '../../service/caller';
import config from '../../config';

const Tireur = () => {
  const [data, setData] = useState([]);

  const competition = JSON.parse(localStorage.getItem('competition'));
  const serie = localStorage.getItem('serie');
  const load = () => {
    Caller.request(
      'GET',
      `${config.api_path}/competition/licensee/${competition._id}/${serie}`,
      {},
      {
        'x-access-token': localStorage.getItem('arbitre-token'),
      }
    ).then(r => setData(r.data.data))
  }

  useEffect(() => {
    if (data.length === 0) {
      load()
    }
  })

  return (
    <>
      <Tireurs competition={competition} items={data} serie={serie} />
    </>
  );
};

export default Tireur;
