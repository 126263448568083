import React, { useState } from 'react';
import Poste from './Poste';
import styles from '../../../components/CheckoutComplete/CheckoutComplete.module.sass';
import cn from 'classnames';
import Icon from '../../../components/Icon';
import Caller from '../../../service/caller';
import config from '../../../config';
import Modal from '../../../components/Modal';
import Comfirmation from './Comfirmation';

class Selection extends React.Component {
  selected = {
    float: 'left',
    backgroundColor: 'black',
    color: 'white',
    padding: '3px',
    width: '30px',
    height: '30px',
    margin: '3px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  state = {
    selection: [],
    visible: false,
  };

  constructor(props) {
    super(props);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.validate = this.validate.bind(this);

    this.postes = [];

    for (let i = 0; i < 60; i++) {
      this.postes.push(i + 1);
    }
  }

  setVisible(status) {
    this.setState({ visible: status });
  }

  validate() {
    const pin = localStorage.getItem('pin');
    const competition = JSON.parse(localStorage.getItem('competition'));
    const serie = localStorage.getItem('serie');

    const result = Caller.requestAdmin('POST', `${config.api_path}/competition/poste/${competition._id}/${serie}`, {
      pin: pin,
      postes: this.state.selection,
    }).then((response) => {
      this.setVisible(false);
      window.location.href = '/tireur';
    });
  }

  handleSelected(index) {
    let selection = this.state.selection;

    if (!selection.includes(index)) {
      selection.push(index);
    } else {
      selection = selection.filter((item) => item !== index);
    }

    this.setState({ selection });
  }

  handleSubmit() {
    this.setVisible(true);
  }

  render() {
    const competition = JSON.parse(localStorage.getItem('competition'));
    return (
      <div>
        <div>
          {this.props.data.map((index) => (
            <Poste handleSelected={this.handleSelected} key={index} index={index} />
          ))}
        </div>

        <div style={{ clear: 'both', marginBottom: '30px' }}></div>

        <div className={styles.btns}>
          <div style={{ width: '100%' }}>
            <span style={{ fontSize: '10px' }}>
              <strong>Postes séléctionnées</strong>
            </span>
            <br />
            <span>
              <Icon name={'user'} size="14" />
              <span style={{ marginLeft: '10px', marginTop: '20px' }}>
                {this.state.selection.length > 0 ? this.state.selection.join(' - ') : 'Aucun'}
              </span>
            </span>
          </div>
          <a className={cn('button', styles.button)} onClick={this.handleSubmit}>
            Comfirmer
          </a>
        </div>
        <Modal visible={this.state.visible} onClose={() => this.setVisible(false)}>
          <Comfirmation setVisible={this.setVisible} validate={this.validate} selection={this.state.selection} />
        </Modal>
      </div>
    );
  }
}

export default Selection;
