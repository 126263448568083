import React, { useContext, useEffect } from 'react';
import Competitions from '../../components/Competitions';

// data
import { destinations } from '../../mocks/destinations';
import { Context } from '../../reducer/Store';

const Competition = () => {
  const [state, dispatch] = useContext(Context);
  let discipline = JSON.parse(localStorage.getItem('discipline'));

  useEffect(async () => {
    dispatch({ type: 'SET_DISCIPLINE', payload: discipline });
  }, []);

  return (
    <>
      <Competitions discipline={discipline} items={destinations} />
    </>
  );
};

export default Competition;
