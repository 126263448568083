import React, { useContext, useEffect, useState } from 'react';
import Main from './Main';

// data
import { Context } from '../../reducer/Store';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import styles from '../../components/Competitions/Competitions.module.sass';
import Series from '../../components/Series';
import Caller from '../../service/caller';
import config from '../../config';
import Control from '../../components/Control';
import TextInput from '../../components/TextInput';
import Modal from '../../components/Modal';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Serie = () => {
  const [state, dispatch] = useContext(Context);
  let { competition } = useParams();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pin, setPin] = useState(null);
  const [error, setError] = useState(null)

  useEffect(async () => {
    const result = await Caller.requestAdmin('GET', `${config.api_path}/competition/series/${competition}`);
    setData(result.data.data);
  }, []);

  const breadcrumbs = [
    {
      title: 'Discipline',
    },
    {
      title: 'Compétition',
    },
    {
      title: 'Série',
    },
  ];

  return (
    <>
      <Main competition={competition} />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <h3 style={{textAlign: 'center' }}>Connection Arbitre</h3>
        <div style={{ paddingTop: '30px' }}>
          <TextInput
            autoComplete="off"
            className={styles.field}
            label="Saisissez votre code PIN à 6 chiffres"
            name="pin"
            type="password"
            onChange={ (e) => setPin(e.target.value) }
            required
          />
        </div>
        {error && (
          <div style={{
            padding: '10px',
            backgroundColor: 'pink',
            border: '1px solid red',
            marginTop: '20px',
            marginBottom: '20px'
          }}>{error}</div>
        )}

        <div className={styles.item} style={{ textAlign: 'right' }}>
          <button
            className={cn('button-small', styles.button)}
            onClick={() => {
              if (!pin.match(/[0-9]{6}/)) {
                setError('Le code pin doit etre compose de 6 chiffres')
                return false
              }
              Caller.authPin(`${config.api_path}/auth/sign-in/referee`, {
                competition: competition,
                pin: pin,
              }).then(response => {
                localStorage.setItem('arbitre-token', response?.data?.data?.token)
                localStorage.setItem('serie', response?.data?.data?.series)
                localStorage.setItem('pin', pin)
                window.location.href = '/poste-tireur'
              }).catch(error => {
                setError('Le code PIN saisi n\'est pas valide !')
              })
            }}
          >Se connecter</button>
        </div>
      </Modal>

      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className={cn('h2', styles.title)} style={{float: 'left'}}>
              Séléctionnez
              <br />
              une série
            </h2>
            <div style={{float: 'right', marginTop: '-10px'}}>
              <button
                className={cn('button-small', styles.button)}
                onClick={ () => {
                  setError(null)
                  setVisible(true)
                }}
              >
                Connection
              </button>
            </div>
            <div style={{clear: 'both'}} />
          </div>
          <div className={styles.wrapper}></div>
        </div>
      </div>

      <Series series={data} />
    </>
  );
};

export default Serie;
