import React, { useState } from 'react';
import cn from 'classnames';
import styles from './SerieLocation.module.sass';
import Icon from '../Icon';

const SerieLocation = ({ item, className, icon, small, bodyDown }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={cn(
        className,
        styles.location,
        { [styles.small]: small },
        { [styles.bodyDown]: bodyDown },
        { [styles.active]: visible },
      )}
    >
      <div className={styles.head}>
        <div className={styles.icon}>
          <Icon name={icon} size="24" />
        </div>
        <div
          style={{ marginTop: '25px', fontSize: '20px', fontWeight: 'bold', color: 'black' }}
          className={styles.description}
        >
          {item}
        </div>
      </div>
    </div>
  );
};

export default SerieLocation;
