import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Tireurs.module.sass';
import Slider from 'react-slick';
import Tireur from '../Tireur';
import Icon from '../Icon';
import Control from '../Control';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Tireurs = ({ items, serie, competition }) => {
  const [data, setData] = useState([]);

  const breadcrumbs = [
    {
      title: 'Discipline',
    },
    {
      title: 'Compétition',
    },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className={cn('h2', styles.title)}>
              Validez les tireurs
              <br />
              pour chaque poste de tir
            </h2>
          </div>
          <div className={styles.wrapper}>
            <Slider className="destinations-slider" {...settings}>
              {items.map((x, index) => (
                <Tireur competition={competition} serie={serie} className={styles.destination} item={x} key={index} />
              ))}
            </Slider>
          </div>
          <div style={{ padding: '30px', textAlign: 'center' }}>
            <a
              className={cn('button button-small', styles.button)}
              onClick={() => {
                const comp = JSON.parse(localStorage.getItem('competition'));
                window.location.href = '/poste-tireur';
              }}
            >
              Accéder aux postes de tir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tireurs;
