import React from 'react';

const Tireur = ({ className, item, serie, competition }) => {
  const posteds = JSON.parse(localStorage.getItem('posteds') || []);
  const currentVolee = posteds.filter((obj) => {
    return !!obj.posted.find((posted) => {
      return posted.id === item.id;
    });
  });

  return (
    <div>
      <div>
        <span
          style={{
            fontSize: '72px',
            fontWeight: 'bold',
            display: 'block',
            textAlign: 'center',
            backgroundColor: item.status.toLowerCase() === 'ok' ? '#3B71FE' : 'lightgray',
            color: 'white',
            borderRadius: '12%',
            width: '200px',
            height: '200px',
            float: 'left',
          }}
        >
          <div style={{ position: 'relative', right: '-70px', top: '0px', fontSize: '18px', padding: '10px' }}>
            {item.volley > 0 ? item.volley : '\u00A0'}
          </div>
          <div
            onClick={() => {
              if (item.status.toLowerCase() !== 'ok') {
                return false;
              }
              localStorage.setItem('poste', JSON.stringify(item));
              //window.location.href = `/score/${item.volley + 1}`;
              window.location.href = `/scoring`;
            }}
            style={{ marginTop: '-10px' }}
          >
            {item.position}
            {item.grade}
          </div>
        </span>
      </div>
      <div style={{ clear: 'both' }}></div>
      <div>
        <strong>
          {item.firstname}
          <br /> {item.lastname}
        </strong>
      </div>
    </div>
  );
};

export default Tireur;
