import Caller from '../../service/caller';
import config from '../../config';

const ValidateGreffier = ({
  backgroundColor,
  color,
  content,
  competition,
  poste,
  setLoading,
  volee,
  validate,
  setValidate,
  expectedVolley,
}) => {
  return (
    <div
      onClick={async (e) => {
        setLoading(true);
        const response = await Caller.request(
          'put',
          `${config.api_path}/competition/score/${competition._id}`,
          {
            licensee: poste.id,
            volley: volee,
          },
          {
            'x-access-token': localStorage.getItem('arbitre-token'),
          },
        );
        setLoading(false);
        setValidate(2);

        if (response.data.next !== null) {
          localStorage.setItem('poste', JSON.stringify(response.data.next))
          window.location.href = '/scoring';
        } else {
          window.location.href = '/poste-tireur';
        }
      }}
      style={{
        opacity: !validate ? '0.3' : '1',
        backgroundColor: backgroundColor,
        color: color || 'black',
        margin: '2px',
        padding: '10px',
        textAlign: 'center',
        width: '100%',
        border: '1px solid black',
      }}
    >
      Validation {content}
    </div>
  );
};

export default ValidateGreffier;
