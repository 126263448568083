import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Competition.module.sass';
import Icon from '../Icon';
import moment from 'moment';

const Competition = ({ className, item }) => {
  return (
    <div
      onClick={() => {
        localStorage.setItem('competition', JSON.stringify(item));
        window.location.href = `/serie/${item._id}`;
      }}
      className={cn(className, styles.destination)}
    >
      <div className={styles.preview}>
        <img src={`/images/competition/${item.image || 'stq.png'}`} alt={item.name} />
        <div className={cn('status', styles.category, { 'status-black': true })}>{item.type}</div>
      </div>
      <div style={{ width: '50%' }} className={styles.title}>
        {item.name}
      </div>
      <div className={styles.content}>
        <div>
          <Icon style={{ marginRight: '20px' }} name={'calendar'} size="20" />
          <span style={{ marginLeft: '10px' }}>{item.period}</span>
        </div>
        <div style={{ marginTop: '10px'}}>
          <Icon name={'location'} size="20" />
          <span style={{ marginLeft: '10px' }}>{item.location}</span>
        </div>
      </div>
    </div>
  );
};

export default Competition;
