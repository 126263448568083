import axios from 'axios';

class Caller {
  static async authPin (url, params) {
    return axios({
      method: 'POST',
      url: url,
      data: params
    }).then(r => r.data.data)
  }
  static async requestAdmin(method, url, params, headers = null) {
    const token = localStorage.getItem('admin-token');
    return axios({
      method: method,
      url: url,
      headers: headers
        ? headers
        : {
            'x-access-token': token,
          },
      data: params,
    }).catch((error) => {
      if (error.response.status === 403) {
        localStorage.setItem('admin-token', null);
        window.location.href = '/admin/';
      }
      throw error;
    });
  }

  static async request(method, url, params, headers = null) {
    const token = localStorage.getItem('arbitre-token');
    return axios({
      method: method,
      url: url,
      headers: headers
        ? headers
        : {
            'x-access-token': token,
          },
      data: params,
    }).catch((error) => {
      if (error.response.status === 403) {
        window.location.href = '/';
      }
    });
  }
}

export default Caller;
