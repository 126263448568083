const TableSummary = ({ session, state, sessionNumber, config, competition }) => {
  const cellStyle = {
    width: '20%',
    paddingTop: '5px',
    paddingbottom: '5px',
    border: '1px solid black',
    textAlign: 'center',
  }

  return (
    <table style={{
      width: '30%',
      float: 'left',
      marginTop: '20px',
      marginLeft: '20px',
      borderCollapse: 'separate',
      borderSpacing: '2px 2px',
      tableLayout: 'fixed'
    }}>
      <thead>
        <tr>
          <th colSpan={4} style={{ textAlign: 'center' }}>&nbsp;</th>
        </tr>
        <tr>
          {['T', 'M', '10', '9', 'Cps'].map((index) => {
            return (
              <th style={{...cellStyle, backgroundColor: 'navy', color: 'white'}}>
                {index}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {/* Total per line */}
        {session.map((arrows, i) => {
          return (
            <tr>
              <td style={cellStyle}>{arrows.total.score}</td>

              <td style={cellStyle}>{arrows.total.bullseye}</td>

              <td style={cellStyle}>{arrows.total.nb10}</td>

              <td style={cellStyle}>{arrows.total.nb9}</td>

              <td style={cellStyle}>{arrows.total.hits}</td>
            </tr>
          )
        })}
      </tbody>

      <tfoot>
        {/* Session sub total */}
        <tr>
          <td style={cellStyle}>
            {session.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total.score
            }, 0)}
          </td>

          <td style={cellStyle}>
            {session.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total.bullseye
            }, 0)}
          </td>

          <td style={cellStyle}>
            {session.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total.nb10
            }, 0)}
          </td>
          <td style={cellStyle}>
            {session.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total.nb9
            }, 0)}
          </td>

          <td style={cellStyle}>
            {session.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total.hits
            }, 0)}
          </td>
        </tr>

        {/* Global Total */}
        <tr style={{color: 'red', fontWeight: 'bold'}}>
          <td style={cellStyle}>
            {state.score}
          </td>

          <td style={cellStyle}> {state.bullseye}</td>

          <td style={cellStyle}>{state.nb10}</td>

          <td style={cellStyle}>{state.nb9}</td>

          <td style={cellStyle}>{state.hits}</td>
        </tr>
      </tfoot>
    </table>
  )
}

export default TableSummary
