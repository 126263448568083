import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from './Header.module.sass';
import { Link, NavLink } from 'react-router-dom';
import Image from '../Image';

import Modal from '../Modal';
import Login from '../Login';
import { Context } from '../../reducer/Store';

const Header = ({ separatorHeader, wide, notAuthorized }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const [state, dispatch] = useContext(Context);

  let discipline = '';
  try {
    discipline = JSON.parse(localStorage.getItem('discipline'));
  } catch (e) {}

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'transparent',
          top: '0px',
          left: '0px',
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            width: '20px',
            zIndex: 99,
            height: '20px',
            position: 'relative',
            backgroundColor: 'blue',
            top: `${state.pointer.clientY - 10}px`,
            left: `${state.pointer.clientX - 10}px`,
            borderRadius: '50%',
            display: state.pointer.clientY !== 0 ? 'block' : ' none',
          }}
        ></div>
      </div>
      <div className={cn(styles.header, { [styles.headerBorder]: separatorHeader }, { [styles.wide]: wide })}>
        <div className={cn('container', styles.container)}>
          <Link className={styles.logo} to="/">
            <Image className={styles.pic} src="/images/logo-fft.svg" srcDark="/images/logo-fft.svg" alt="Logo FFT" />
          </Link>
          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            <img
              style={{ height: '50px', marginRight: '50px' }}
              src={`/images/logo-shootetic.png`}
              alt={`Icon ${state.discipline}`}
            />
          </div>
          <span style={{ fontWeight: 'bold', marginRight: '20px' }}> Arbalète Field</span>
          {state.discipline || discipline ? (
            <img
              style={{ position: 'static', height: '50px', width: '50px' }}
              src={`/images/icon/${state.discipline.slug || discipline.slug}.svg`}
              alt={`Icon ${state.discipline}`}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal visible={false} onClose={() => setVisible(false)}>
        <Login />
      </Modal>
    </>
  );
};

export default Header;
