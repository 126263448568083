import React, { useState } from 'react';
import Button from './button';
import Rewind from './rewind';
import Validate from './validate';
import ValidateGreffier from './validateGreffier';
import Abs from './abs';

const Points = ({
  data,
  competition,
  setData,
  setLoading,
  loading,
  poste,
  finish,
  incident,
  incidentStyle,
  setVisible,
}) => {
  const [validate, setValidate] = useState(0);
  const volley = incident?.volley || data?.state?.volley;
  const arrow = incident?.arrow || data?.state?.arrows?.next;

  return (
    <div
      style={{ width: incidentStyle ? '100%' : '35%', textAlign: 'left', float: 'left', opacity: finish ? '0.3' : '1' }}
    >
      <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '3px' }}>
        <tbody>
          <tr>
            <td colSpan={2} style={{ width: '50%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={'Mouche'}
                value={10}
                backgroundColor={'yellow'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
                bullseye={true}
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={10}
                backgroundColor={'yellow'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                style={{ float: 'left', margin: '10px' }}
                poste={poste}
                arrow={arrow}
                content={9}
                backgroundColor={'yellow'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '50%', textAlign: 'right' }}>
              <Button
                incident={incident}
                position={'right'}
                poste={poste}
                arrow={arrow}
                content={'Cible Manquée'}
                value={-2}
                backgroundColor={'white'}
                color={'black'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={8}
                backgroundColor={'red'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={7}
                backgroundColor={'red'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '50%' }}>
              {!incidentStyle ? (
                <Rewind
                  position={'right'}
                  poste={poste}
                  arrow={arrow}
                  content={'Effacer dernière saisie'}
                  backgroundColor={'white'}
                  color={'black'}
                  colspan={2}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setVisible={setVisible}
                />
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={6}
                backgroundColor={'skyblue'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                style={{ float: 'left', margin: '10px' }}
                poste={poste}
                arrow={arrow}
                content={5}
                backgroundColor={'skyblue'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={4}
                backgroundColor={'black'}
                color={'white'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                style={{ float: 'left', margin: '10px' }}
                poste={poste}
                arrow={arrow}
                content={3}
                backgroundColor={'black'}
                color={'white'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '50%' }}>
              {!incidentStyle ? (
                <Button
                  incident={incident}
                  position={'right'}
                  poste={poste}
                  arrow={arrow}
                  content={'Incident'}
                  value={-1}
                  backgroundColor={'lightpink'}
                  color={'black'}
                  colspan={2}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setVisible={setVisible}
                />
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                poste={poste}
                arrow={arrow}
                content={2}
                backgroundColor={'white'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td style={{ width: '25%' }}>
              <Button
                incident={incident}
                style={{ float: 'left', margin: '10px' }}
                poste={poste}
                arrow={arrow}
                content={1}
                backgroundColor={'white'}
                competition={competition}
                volee={volley}
                setData={setData}
                setLoading={setLoading}
                loading={loading}
                setVisible={setVisible}
              />
            </td>
            <td colSpan={2} style={{ width: '50%' }}>
              {!incidentStyle && (
                <Validate
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  content={'tireurs'}
                  value={-1}
                  backgroundColor={'lightgreen'}
                  color={'black'}
                  colspan={2}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              )}
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              {!incidentStyle && (
                <Abs
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  totalArrow={data?.config?.arrows}
                  content={'ABS'}
                  backgroundColor={'lightblue'}
                  color={'black'}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              )}
            </td>
            <td style={{ width: '25%' }}>
              {!incidentStyle && (
                <Abs
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  totalArrow={data?.config?.arrows}
                  content={'DSQ'}
                  backgroundColor={'lightblue'}
                  color={'black'}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              )}
            </td>

            <td style={{ width: '50%' }}>
              {!incidentStyle ? (
                <ValidateGreffier
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  expectedVolley={data?.config?.arrows / 3}
                  content={'greffier'}
                  value={-1}
                  backgroundColor={'lightgreen'}
                  color={'black'}
                  colspan={2}
                  competition={competition}
                  volee={volley !== null ? volley - 1 : data?.config?.arrows / 3}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }}>
              {!incidentStyle && (
                <Abs
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  totalArrow={data?.config?.arrows}
                  content={'DNS'}
                  backgroundColor={'lightblue'}
                  color={'black'}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              )}
            </td>
            <td style={{ width: '25%' }}>
              {!incidentStyle && (
                <Abs
                  style={{ float: 'left', margin: '10px' }}
                  poste={poste}
                  arrow={arrow}
                  totalArrow={data?.config?.arrows}
                  content={'ABD'}
                  backgroundColor={'lightblue'}
                  color={'black'}
                  competition={competition}
                  volee={volley}
                  setData={setData}
                  setLoading={setLoading}
                  loading={loading}
                  setValidate={setValidate}
                  validate={validate}
                  setVisible={setVisible}
                />
              )}
            </td>

            <td style={{ width: '50%' }}>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Points;
