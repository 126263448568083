import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import styles from './Page.module.sass';
import Header from '../Header';

const Page = ({ separatorHeader, children, fooferHide, wide, notAuthorized }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <>
      <div className={styles.page}>
        <Header separatorHeader={separatorHeader} wide={wide} notAuthorized={notAuthorized} />
        <div className={styles.inner}>{children}</div>
      </div>
    </>
  );
};

export default withRouter(Page);
