import React, { useState } from 'react';
import cn from 'classnames';
import styles from './ConfirmAndPay.module.sass';
import TextInput from '../TextInput';

class ConfirmAndPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lastname: '', licence: '', pin: '' };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
    this.handleChangeLast = this.handleChangeLast.bind(this);
    this.handleChangeLicence = this.handleChangeLicence.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitConnexion = this.handleSubmitConnexion.bind(this);
  }

  handleChangePin(event) {
    this.setState({ pin: event.target.value });
  }
  handleChangeName(event) {
    this.setState({ lastname: event.target.value });
  }
  handleChangeLast(event) {
    this.setState({ firstname: event.target.value });
  }
  handleChangeLicence(event) {
    this.setState({ licence: event.target.value });
  }

  async handleSubmitConnexion(event) {
    event.preventDefault();
    if (this.state.pin.length === 6) {
      localStorage.setItem('pin', this.state.pin);
      window.location.href = '/poste-tireur';
    } else {
      alert('Le Code Pin doit faire 6 caractères');
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.pin.length === 6) {
      localStorage.setItem('pin', this.state.pin);
      window.location.href = '/poste-de-tir';
    } else {
      alert('Le Code Pin doit faire 6 caractères');
    }
  }

  render() {
    return (
      <div className={cn(this.props.className, styles.confirm)}>
        <div className={cn('h2', styles.title)}>Assignez un arbitre</div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="Choisissez un code de 6 chiffres"
                onChange={this.handleChangePin}
                name="pin"
                type="integer"
                placeholder="Entrez le code"
                required
              />
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={this.handleSubmit} className={cn('button-stroke', styles.button)}>
              <span>Ajouter arbitre</span>
            </button>
          </div>
        </div>

        <div style={{ marginTop: '40px' }} className={cn('h2', styles.title)}>
          Connexion arbitre
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="Saisissez votre code à 6 caractères"
                onChange={this.handleChangePin}
                name="pin"
                type="integer"
                placeholder="Entrez le code"
                required
              />
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={this.handleSubmitConnexion} className={cn('button-stroke', styles.button)}>
              <span>Connexion</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmAndPay;
