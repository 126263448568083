import React, { useState } from 'react';

class Poste extends React.Component {
  selected = {
    float: 'left',
    backgroundColor: 'black',
    color: 'white',
    padding: '3px',
    width: '30px',
    height: '30px',
    margin: '3px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  unselected = {
    float: 'left',
    backgroundColor: 'white',
    color: 'black',
    padding: '3px',
    width: '30px',
    height: '30px',
    margin: '3px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  deactivated = {
    float: 'left',
    backgroundColor: 'lightgrey',
    color: 'darkgrey',
    padding: '3px',
    width: '30px',
    height: '30px',
    margin: '3px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  state = {
    selected: false,
  };

  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }

  changeState(e) {
    if (this.props.index.referee !== false || this.props.index.licensee !== true) {
      return true;
    }
    this.setState({
      selected: !this.state.selected,
    });
    this.props.handleSelected(this.props.index.position);
  }

  render() {
    return (
      <div
        onClick={this.changeState}
        key={this.props.index.position}
        style={
          this.state.selected
            ? this.selected
            : this.props.index.referee === false && this.props.index.licensee === true
            ? this.unselected
            : this.deactivated
        }
      >
        {this.props.index.position}
      </div>
    );
  }
}

export default Poste;
