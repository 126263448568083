import React from 'react';
import cn from 'classnames';
import styles from './Series.module.sass';
import Slider from 'react-slick';
import Item from './Item';
import Icon from '../Icon';

// data

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Series = ({ series, classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 100000,
        settings: 'unslick',
      },
    ],
  };

  return (
    <div style={{ marginTop: '70px' }} className={cn('section', classSection, styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.inner}>
          <div className={styles.wrapper} style={{ padding: '0px' }}>
            <Slider
              style={{ backgroundColor: '#FCFCFDD4' }}
              className={cn('places-slider', styles.slider)}
              {...settings}
            >
              {series.map((x, index) => (
                <Item
                  competition={JSON.parse(localStorage.getItem('competition'))}
                  discipline={JSON.parse(localStorage.getItem('discipline'))}
                  className={styles.item}
                  item={x}
                  key={index}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Series;
