import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './CheckoutComplete.module.sass';
import Icon from '../Icon';
import moment from 'moment';
import Caller from '../../service/caller';
import config from '../../config';

const CheckoutComplete = ({ className, title, parameters, options, items }) => {
  const serie = JSON.parse(localStorage.getItem('serie'));
  const discipline = JSON.parse(localStorage.getItem('discipline'));
  const competition = JSON.parse(localStorage.getItem('competition'));
  const [arbitre, setArbitre] = useState({});

  useEffect(async () => {
    Caller.request(
      'GET',
      `${config.api_path}/me`,
      {},
      {
        'x-access-token': localStorage.getItem('arbitre-token'),
      },
    ).then((response) => {
      const arbitre = response.data.data;
      setArbitre(arbitre);
    });
  });

  return (
    <div className={cn(className, styles.complete)}>
      <div className={styles.head}>
        <div className={cn('h2', styles.title)}>Arbitre assigné !</div>
        <div className={styles.info}>
          L'arbitre {arbitre.firstname} {arbitre.lastname} à été ajouté avec succès
        </div>
        <div className={styles.subtitle}>Série {serie}</div>
        <div className={styles.author}>
          <div className={styles.avatar}>
            <img src={`/images/icon/${discipline.slug}.svg`} alt="Discipline" />
          </div>
          <div className={styles.man}>{discipline.name}</div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <Icon name={'calendar'} size="20" />
            {'Date et horaire :'}
          </div>
          <div className={styles.cell}>{moment(competition.from).format('DD/MM/YYYY')}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <Icon name={'cup'} size="20" />
            {'Competition :'}
          </div>
          <div className={styles.cell}>{competition.name}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <Icon name={'cible'} size="20" />
            {'Epreuve :'}
          </div>
          <div className={styles.cell}>{competition.type}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <Icon name={'location'} size="20" />
            {'Localisation :'}
          </div>
          <div className={styles.cell}>{competition.location}</div>
        </div>
      </div>

      <div className={styles.btns}>
        <Link className={cn('button-stroke', styles.button)} to="/your-trips">
          Annuler
        </Link>

        {localStorage.getItem(`configuration-${competition._id}-${arbitre._id}`) ? (
          <Link className={cn('button', styles.button)} to="/poste-tireur">
            Continuer
          </Link>
        ) : (
          <Link className={cn('button', styles.button)} to="/poste-de-tir">
            Continuer
          </Link>
        )}
      </div>
    </div>
  );
};

export default CheckoutComplete;
