import React from 'react';
import cn from 'classnames';
import styles from './Confirm.module.sass';
import Control from '../../components/Control';
import CheckoutComplete from '../../components/CheckoutComplete';

const breadcrumbs = [];
const parameters = [];
const options = [];
const items = [];

const comfirm = () => {
  const competition = JSON.parse(localStorage.getItem('competition'));

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.preview}>
              <img src={`/images/competition/${competition.image || 'stq.png'}`} alt="competition" />
            </div>
          </div>
          <div className={styles.col}>
            <CheckoutComplete
              className={styles.complete}
              title="Spectacular views of Queenstown"
              parameters={parameters}
              options={options}
              items={items}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default comfirm;
