import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Competitions.module.sass';
import Slider from 'react-slick';
import Competition from '../Competition';
import Icon from '../Icon';
import axios from 'axios';
import config from '../../config';
import Control from '../Control';
import Caller from '../../service/caller';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Competitions = ({ items, discipline }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    const result = await Caller.requestAdmin('GET', `${config.api_path}/competition?discipline=${discipline.slug}`);
    setData(result.data.data);
  }, []);

  const breadcrumbs = [
    {
      title: 'Discipline',
    },
    {
      title: 'Compétition',
    },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className={cn('h2', styles.title)}>
              Séléctionnez
              <br />
              une compétition
            </h2>
          </div>
          <div className={styles.wrapper}>
            <Slider className="destinations-slider" {...settings}>
              {data.map((x, index) => (
                <Competition className={styles.destination} item={x} key={index} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitions;
