const TableSummary = ({ sessions, competition, config }) => {
  const cellStyle = {
    paddingTop: '5px',
    paddingbottom: '5px',
    border: '1px solid black',
    textAlign: 'center'
  }

  return (
    <>
      <table style={{
        width: '65%',
        float: 'left' ,
        borderCollapse: 'separate',
        borderSpacing: '0px 2px',
        tableLayout: 'fixed'
      }}>
        <thead>
          <tr>
            <th style={{...cellStyle, border: '1px solid white', color: 'white'}}>&nbsp;</th>

            {Array.from({length: 10}, (v, i) => 10).map((index) => (
              <th style={{...cellStyle, border: '1px solid white', color: 'white'}}>{index}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        {sessions.map((session, i) => (
          <tr>
            <td colSpan={11} style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '7px'}}>
              Session {i+1} { config.distances.length > 0 && config.distances[i] && `(${config.distances[i]}m)` }
            </td>
          </tr>
        ))}
        </tbody>
      </table>

      <table
        style={{
          width: '30%',
          float: 'left',
          marginLeft: '20px',
          borderCollapse: 'separate',
          borderSpacing: '2px 2px',
          tableLayout: 'fixed'
        }}
      >
        <tr>
          {['T', 'M', '10', '9', 'Cps'].map((index) => {
            /*
            if (index === 'M' && competition.type !== 'IR900') {
              return ('')
            }
            */
            return (
              <td style={{ ...cellStyle, color: 'white', backgroundColor: 'navy' }}>
                {index}
              </td>
            );
          })}
        </tr>
        {/* Session sub total */}
        {sessions.map((session, i) => (
          <tr>
            <td style={cellStyle}>
              {session.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total.score;
              }, 0)}
            </td>

            <td style={cellStyle}>
              {session.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total.bullseye;
              }, 0)}
            </td>

            <td style={cellStyle}>
              {session.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total.nb10;
              }, 0)}
            </td>
            <td style={cellStyle}>
              {session.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total.nb9;
              }, 0)}
            </td>

            <td style={cellStyle}>
              {session.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total.hits;
              }, 0)}
            </td>
          </tr>
        ))}
      </table>
    </>
  )
}

export default TableSummary
