import React, { useState } from 'react';
import cn from 'classnames';
import styles from '../Comfirmation.module.sass';

const Confirmation = ({ selection, validate, setVisible }) => {
  return (
    <div className={cn(styles.login)}>
      <div className={styles.item}>
        <div className={cn('h3', styles.title)}>Paramétrage finalisé!</div>
        <div className={styles.info}>Postes : {selection.length > 0 ? selection.join(' - ') : 'Aucun'}</div>

        <div className={styles.note}>
          <a onClick={validate} style={{ width: '100%' }} className={cn('button button-small', styles.button)}>
            Valider les tireurs
          </a>
        </div>
        <div className={styles.note}>
          <a
            onClick={() => setVisible(false)}
            style={{ width: '100%' }}
            className={cn('button-stroke button-small', styles.button)}
          >
            Annuler
          </a>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
