import React from 'react';
import cn from 'classnames';
import styles from './Control.module.sass';
import { Link, useHistory } from 'react-router-dom';

import Icon from '../Icon';

const Control = ({ className, title }) => {
  const navigate = useHistory();

  return (
    <div className={cn(className, styles.control)}>
      <Link className={cn('button-stroke button-small', styles.button)} to={''} onClick={navigate.goBack}>
        <Icon name="arrow-left" size="10" />
        <span>{title || 'Précédent'}</span>
      </Link>
    </div>
  );
};

export default Control;
