import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Poste.module.sass';
import stylesRecap from './Poste-recap.module.sass';
import stylesWidet from './Poste-widget.module.sass';
import Control from '../../components/Control';

import { Context } from '../../reducer/Store';
import Icon from '../../components/Icon';
import Selection from './components/Selection';
import Caller from '../../service/caller';
import config from '../../config';
import moment from 'moment';

const Poste = () => {
  const [data, setData] = useState([]);

  const discipline = JSON.parse(localStorage.getItem('discipline'));
  const competition = JSON.parse(localStorage.getItem('competition'));
  const serie = localStorage.getItem('serie');

  useEffect(async () => {
    const result = await Caller.requestAdmin('GET', `${config.api_path}/competition/poste/${competition._id}/${serie}`);
    setData(result.data.data);
  }, []);

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" />
        <div className={styles.wrapper}>
          <div className={cn(styles.confirm, styles.confirm)}>
            <div className={stylesRecap.head}>
              <div className={cn('h2', stylesRecap.title)}>
                Séléction des postes de tir &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;
              </div>

              <div style={{ marginTop: '120px' }} className={stylesRecap.subtitle}>
                Série {serie}
              </div>
              <div className={stylesRecap.author}>
                <div className={stylesRecap.avatar}>
                  <img src={`/images/icon/${discipline.slug}.svg`} alt="Discipline" />
                </div>
                <div className={stylesRecap.man}>{discipline.name}</div>
              </div>
            </div>

            <div className={stylesRecap.table}>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'calendar'} size="20" />
                  {'Date et horaire :'}
                </div>
                <div className={stylesRecap.cell}>{moment(competition.from).format('DD/MM/YYYY')}</div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'cup'} size="20" />
                  {'Competition :'}
                </div>
                <div className={stylesRecap.cell}>{competition.name}</div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'cible'} size="20" />
                  {'Epreuve :'}
                </div>
                <div className={stylesRecap.cell}>{competition.type}</div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'location'} size="20" />
                  {'Localisation :'}
                </div>
                <div className={stylesRecap.cell}>{competition.location}</div>
              </div>
            </div>
          </div>

          <div className={cn(stylesWidet.price, stylesWidet.price)}>
            <div
              className={cn(stylesWidet.description, {
                [styles.flex]: true,
              })}
            >
              <div className={stylesWidet.item} key={1}>
                <div style={{ width: '100%', textAlign: 'center' }} className={stylesWidet.box}>
                  <div style={{ width: '100%', textAlign: 'center' }} className={stylesWidet.subtitle}>
                    Poste de tir
                  </div>
                  <Selection data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poste;
