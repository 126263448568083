import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Main.module.sass';
import Panel from '../../../components/Panel';
import SerieLocation from '../../../components/SerieLocation';
import { Context } from '../../../reducer/Store';
import config from '../../../config';
import Caller from '../../../service/caller';
import moment from 'moment';

const Main = ({ competition }) => {
  const [state, dispatch] = useContext(Context);
  const [data, setData] = useState({});

  useEffect(async () => {
    const result = await Caller.requestAdmin('GET', `${config.api_path}/competition/${competition}`);
    setData(result.data.data);

    localStorage.setItem('competition', JSON.stringify(result.data.data));
    dispatch({ type: 'SET_COMPETITION', payload: result.data });
  }, []);

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={styles.bg}>
        <img src="/images/background/background-serie.png" alt="Flight" />
      </div>
      <div style={{ marginTop: '50px' }} className={cn('container', styles.container)}>
        <Panel
          className={styles.panel}
          classBody={styles.body}
          classButtonSearch={styles.search}
        >
          <div className={styles.row}>
            <SerieLocation item={data.type} className={styles.location} icon="cible" small bodyDown />
            <SerieLocation item={data.name} className={styles.location} icon="cup" small bodyDown />
            <SerieLocation
              item={moment(data.from).format('DD/MM/YYYY')}
              className={styles.location}
              icon="calendar"
              small
              bodyDown
            />
            <SerieLocation item={data.location} className={styles.location} icon="location" small bodyDown />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Main;
