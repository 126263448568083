import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import styles from './Cible.module.sass';
import stylesRecap from './Cible-recap.module.sass';
import Control from '../../components/Control';
import CibleComponent from './components/Cible';

import { Context } from '../../reducer/Store';
import { useParams } from 'react-router-dom';
import Icon from '../../components/Icon';
import moment from 'moment';
import config from '../../config';
import Caller from '../../service/caller';

const breadcrumbs = [];

const Cible = () => {
  const [state, dispatch] = useContext(Context);

  const poste = JSON.parse(localStorage.getItem('poste'));
  const serie = JSON.parse(localStorage.getItem('serie'));
  const competition = JSON.parse(localStorage.getItem('competition'));

  const { cible, volee } = useParams();

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} title={'Feuille de marquage'} />
        <div className={styles.wrapper}>
          <div className={cn(styles.confirm, styles.confirm)}>
            <div className={stylesRecap.head}>
              <div style={{ fontWeight: 'bold' }} className={cn('h5', stylesRecap.title)}>
                Poste {poste.position}
                {poste.grade} - {poste.firstname} {poste.lastname}
              </div>
              <div>
                Volée n° {volee} flèche {cible}
              </div>
            </div>

            <div className={stylesRecap.table}>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'serie'} size="20" />
                  {'Série' + serie}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'calendar'} size="20" />
                  {moment(competition.from).format('DD/MM/YYYY')}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'cible'} size="20" />
                  {competition.type}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'location'} size="20" />
                  {competition.location}
                </div>
              </div>
            </div>
            <div className={styles.note}>
              <a
                onClick={() => {
                  Caller.request(
                    'post',
                    `${config.api_path}/competition/score/${competition._id}`,
                    {
                      licensee: poste.id,
                      volley: volee,
                      arrow: cible,
                      score: state.score,
                    },
                    {
                      'x-access-token': localStorage.getItem('arbitre-token'),
                    },
                  ).then((response) => {
                    const scoreTable = JSON.parse(localStorage.getItem('score_table') || '[]');
                    let newScoreTable = scoreTable.map((c) => {
                      if (c.cible == cible) {
                        c.volee[volee - 1] = state.score;
                      }
                      return c;
                    });
                    localStorage.setItem('score_table', JSON.stringify(newScoreTable));
                    if (parseInt(cible) === 3) {
                      window.location.href = `/score/${parseInt(volee)}/validate`;
                    } else {
                      window.location.href = `/cible/${parseInt(cible) + 1}/${volee}`;
                    }
                  });
                }}
                style={{ width: '100%', opacity: state.started ? '1' : '0.5' }}
                className={cn('button button-small', styles.button)}
              >
                Cible suivante
              </a>
            </div>
            <div className={styles.note} style={{ marginTop: '20px' }}>
              <a
                onClick={() => {
                  window.location.reload(false);
                }}
                style={{ width: '100%', backgroundColor: 'lightgray', color: 'black' }}
                className={cn('button button-small', styles.button)}
              >
                Recommencer
              </a>
            </div>
          </div>
          <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <CibleComponent width={500} level={0} />
            <div style={{ float: 'right' }}>
              <a
                onClick={() => {
                  Caller.request(
                    'post',
                    `${config.api_path}/competition/score/${competition._id}`,
                    {
                      licensee: poste.id,
                      volley: volee,
                      arrow: cible,
                      score: -1,
                    },
                    {
                      'x-access-token': localStorage.getItem('arbitre-token'),
                    },
                  ).then((response) => {
                    const scoreTable = JSON.parse(localStorage.getItem('score_table') || '[]');
                    let newScoreTable = scoreTable.map((c) => {
                      if (c.cible == cible) {
                        c.volee[volee - 1] = -1;
                      }
                      return c;
                    });
                    localStorage.setItem('score_table', JSON.stringify(newScoreTable));
                    if (parseInt(cible) === 3) {
                      window.location.href = `/score/${parseInt(volee)}/validate`;
                    } else {
                      window.location.href = `/cible/${parseInt(cible) + 1}/${volee}`;
                    }
                  });
                }}
                style={{ width: '100%', color: 'black', cursor: 'pointer', backgroundColor: 'lightgray' }}
                className={cn('button button-small button-white', styles.button)}
              >
                Incident
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cible;
