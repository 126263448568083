import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';

const initialState = {
  discipline: '',
  score: 0,
  started: false,
  score_table: [],
  pointer: { screenX: 0, screenY: 0, clientX: 0, clientY: 0 },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
export default Store;
