import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Cible.module.sass';
import stylesRecap from './Cible-recap.module.sass';
import Control from '../../components/Control';
import CibleComponent from './components/Cible';

import { Context } from '../../reducer/Store';
import { useParams } from 'react-router-dom';
import Icon from '../../components/Icon';
import moment from 'moment';
import config from '../../config';
import Caller from '../../service/caller';

const breadcrumbs = [];

const Incident = () => {
  const [state, dispatch] = useContext(Context);
  const [data, setData] = useState([]);

  const poste = JSON.parse(localStorage.getItem('poste'));
  const serie = JSON.parse(localStorage.getItem('serie'));
  const competition = JSON.parse(localStorage.getItem('competition'));

  const { cible, volee } = useParams();

  useEffect(async () => {
    const result = await Caller.request(
      'GET',
      `${config.api_path}/competition/score/${competition._id}/${poste.id}`,
      {},
      {
        'x-access-token': localStorage.getItem('arbitre-token'),
      },
    );
    setData(result.data.data);
  }, []);

  let incidents = [];
  data?.map((scoreTable, index) => {
    scoreTable.map((cible) => {
      cible.volley.map((volley, index) => {
        if (volley.incident && volley.score === -1) {
          incidents.push({
            arrow: cible.arrow,
            volley: index + 1,
          });
        }
      });
    });
  });

  return (
    <div className={cn('section-mb80', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} title={'Feuille de marquage'} />
        <div className={styles.wrapper}>
          <div className={cn(styles.confirm, styles.confirm)}>
            <div className={stylesRecap.head}>
              <div className={cn('h5', stylesRecap.title)}>
                Poste {poste.position}
                {poste.grade} - {poste.firstname} {poste.lastname}
                <div style={{ clear: 'both' }}></div>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    float: 'left',
                  }}
                ></div>
                <div style={{ float: 'left', fontWeight: 'bold' }}>
                  &nbsp; Volée {incidents[0]?.volley}, Cible {incidents[0]?.arrow}
                </div>
              </div>
            </div>

            <div className={stylesRecap.table}>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'serie'} size="20" />
                  {'Série' + serie}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'calendar'} size="20" />
                  {moment(competition.from).format('DD/MM/YYYY')}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'cible'} size="20" />
                  {competition.type}
                </div>
              </div>
              <div className={stylesRecap.row}>
                <div className={stylesRecap.cell}>
                  <Icon name={'location'} size="20" />
                  {competition.location}
                </div>
              </div>
            </div>
            <div className={styles.note}>
              <a
                onClick={() => {
                  Caller.request(
                    'post',
                    `${config.api_path}/competition/score/${competition._id}`,
                    {
                      licensee: poste.id,
                      volley: incidents[0]?.volley,
                      arrow: incidents[0]?.arrow,
                      score: state.score,
                    },
                    {
                      'x-access-token': localStorage.getItem('arbitre-token'),
                    },
                  ).then((response) => {
                    window.location.href = `/incident`;
                  });
                }}
                style={{ width: '100%' }}
                className={cn('button button-small', styles.button)}
              >
                Valider
              </a>
            </div>
            <div className={styles.note} style={{ marginTop: '20px' }}>
              <a
                onClick={() => {}}
                style={{ width: '100%', backgroundColor: 'white', color: 'black' }}
                className={cn('button button-small', styles.button)}
              >
                Recommencer
              </a>
            </div>
          </div>
          <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            {incidents.length > 0 ? (
              <CibleComponent width={500} level={0} />
            ) : (
              <div>
                Pas d'incident o traiter <br />{' '}
                <div style={{ width: '100%', padding: '50px' }}>
                  <a
                    onClick={() => {
                      window.location.href = '/poste-tireur';
                    }}
                    className={cn('button button-small button-black', styles.button)}
                  >
                    Retourner sur la fiche
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Incident;
