import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/app.sass';
import Page from './components/Page';

import Home from './pages/Home';
import Competition from './pages/Competition';
import Serie from './pages/Serie';
import Arbitre from './pages/Arbitre';
// import Awards from './pages/Awards';
// import Admin from './pages/Admin';
// import User from './pages/AccountSettings';
// import Plan from './pages/Plan';
import Comfirm from './pages/Comfirm';
import Poste from './pages/Poste';
import Cible from './pages/Cible';
import Incident from './pages/Cible/incident';
import Tireur from './pages/Tireur';
import PosteTireur from './pages/PosteTireur';
import Score from './pages/Score';
import Scoring from './pages/Score/scoring';
// import EditScore from './pages/EditScore';

import Store from './reducer/Store';

function App() {
  return (
    <Store>
      <Router>
        <Switch>
        {/*
          <Route
            exact
            path="/admin"
            render={() => (
              <Page>
                <Admin />
              </Page>
            )}
          />
          <Route
            exact
            path="/admin/user"
            render={() => (
              <Page>
                <User />
              </Page>
            )}
          />
          <Route
            exact
            path="/admin/plan/:competition"
            render={() => (
              <Page>
                <Plan />
              </Page>
            )}
          />

          <Route
            exact
            path="/admin/live-score/:competition"
            render={() => (
              <Page>
                <Awards />
              </Page>
            )}
          />

          <Route
            exact
            path="/admin/edit-score/:competition/:licensee"
            render={() => (
              <Page>
                <EditScore />
              </Page>
            )}
          />
          */}

          <Route
            exact
            path="/"
            render={() => (
              <Page>
                <Home />
              </Page>
            )}
          />
          <Route
            exact
            path="/competition/:discipline"
            render={() => (
              <Page>
                <Competition />
              </Page>
            )}
          />
          <Route
            exact
            path="/serie/:competition"
            render={() => (
              <Page>
                <Serie />
              </Page>
            )}
          />
          <Route
            exact
            path="/arbitre/:serie"
            render={() => (
              <Page>
                <Arbitre />
              </Page>
            )}
          />
          <Route
            exact
            path="/confirm"
            render={() => (
              <Page>
                <Comfirm />
              </Page>
            )}
          />
          <Route
            exact
            path="/poste-de-tir"
            render={() => (
              <Page>
                <Poste />
              </Page>
            )}
          />
          <Route
            exact
            path="/tireur"
            render={() => (
              <Page>
                <Tireur />
              </Page>
            )}
          />
          <Route
            exact
            path="/poste-tireur"
            render={() => (
              <Page>
                <PosteTireur />
              </Page>
            )}
          />
          <Route
            exact
            path="/scoring"
            render={() => (
              <Page>
                <Scoring />
              </Page>
            )}
          />
          <Route
            exact
            path="/score/:volee/:validate?"
            render={() => (
              <Page>
                <Score />
              </Page>
            )}
          />
          <Route
            exact
            path="/cible/:cible/:volee"
            render={() => (
              <Page>
                <Cible />
              </Page>
            )}
          />
          <Route
            exact
            path="/incident"
            render={() => (
              <Page>
                <Incident />
              </Page>
            )}
          />
        </Switch>
      </Router>
    </Store>
  );
}

export default App;
