import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Main.module.sass';
import Box from '../../../components/BoxLogin';
import Caller from '../../../service/caller';
import config from '../../../config';

const Main = () => {
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    Caller.request(
      'GET',
      `${config.api_path}/auth/competition`
    ).then(r => setCompetitions(r.data.data))
  }, [])

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.preview}>
          <picture to={'test.fr'}>
            <source media="(max-width: 767px)" srcSet="/images/background/main-background.png" />
            <img src="/images/background/main-background.png" alt="Bakcground selection" />
          </picture>

          <div className={styles.wrap}>
            {competitions.length > 0 ? (
              <>
                <h1 style={{ fontSize: '68px' }} className={cn('hero', styles.title)}>
                  Sélectionnez <br />
                  une compétition
                </h1>
                <Box competitions={competitions} />
              </>
            ) : (
              <h1 style={{ fontSize: '68px' }} className={cn('hero', styles.title)}>
                Aucune compétition <br />
                en cours
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
