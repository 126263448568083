const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DISCIPLINE':
      return {
        ...state,
        discipline: action.payload,
      };
    case 'SET_SCORE_TABLE':
      return {
        ...state,
        score_table: action.payload,
      };
    case 'SET_POINTER':
      if (state.score === 0) {
        return {
          ...state,
          pointer: action.payload,
        };
      }
      return state;
    case 'SET_SCORE':
      state.started = true;
      if (state.score < action.payload && state.score === 0) {
        return {
          ...state,
          score: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};

export default Reducer;
