import React from 'react';

const Validate = ({ backgroundColor, color, content, setValidate, validate, arrow, volee, loading }) => {
  console.log('Loading', loading)
  console.log('Validate', validate)
  return (
    <div
      onClick={async (e) => {
        setValidate(1);
      }}
      style={{
        opacity: (loading === false || validate !== 0) ? '0.3' : '1',
        backgroundColor: backgroundColor,
        color: color || 'black',
        margin: '2px',
        padding: '10px',
        textAlign: 'center',
        width: '100%',
        border: '1px solid black',
      }}
    >
      Validation {content}
    </div>
  );
};

export default Validate;
