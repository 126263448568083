import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Tireurs.module.sass';
import Slider from 'react-slick';
import Tireur from '../PosteTireur';
import Icon from '../Icon';
import Control from '../Control';
import moment from 'moment';

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => <button {...props}>{children}</button>;

const Tireurs = ({ items, serie, competition }) => {
  let posteds = [];
  for (let i = 1; i <= 10; i++) {
    posteds.push({ index: i, posted: [] });
  }
  if (!localStorage.getItem('posteds')) {
    localStorage.setItem('posteds', JSON.stringify(posteds));
  }

  const breadcrumbs = [
    {
      title: 'Discipline',
    },
    {
      title: 'Compétition',
    },
  ];

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 8,
        },
      },
    ],
  };

  return (
    <div className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <Control className={styles.control} urlHome="/" breadcrumbs={breadcrumbs} />
        <div className={styles.inner}>
          <div className={styles.head}>
            <h2 className={cn('h2', styles.title)}>Poste de tirs</h2>
          </div>
          <div style={{ marginTop: '-30px' }} className={styles.info}>
            Sélectionnez un poste de tir pour démarrer la saisie
          </div>
          <div style={{ marginTop: '-30px', padding: '10px' }} className={styles.info}>
            <div style={{ float: 'left', padding: '10px' }}>
              <Icon name={'serie'} size={24} />
              <span style={{ marginLeft: '5px' }}>Série {serie}</span>
            </div>
            <div style={{ float: 'left', padding: '10px' }}>
              <Icon name={'calendar-grey'} size={24} />
              <span style={{ marginLeft: '5px' }}>{moment(competition.from).format('DD/MM/YYYY')}</span>
            </div>
            <div style={{ float: 'left', padding: '10px' }}>
              <Icon name={'cible'} size={24} />
              <span style={{ marginLeft: '5px' }}>{competition.type}</span>
            </div>
            <div style={{ float: 'left', padding: '10px' }}>
              <Icon name={'cup'} size={24} />
              <span style={{ marginLeft: '5px' }}>{competition.name}</span>
            </div>
            <div style={{ float: 'left', padding: '10px' }}>
              <Icon name={'location-grey'} size={24} />
              <span style={{ marginLeft: '5px' }}>{competition.location}</span>
            </div>
          </div>
          <div style={{ clear: 'both', marginTop: '100px' }}></div>
          <div className={styles.wrapper}>
            <Slider className="destinations-slider" {...settings}>
              {items.map((x, index) => (
                <Tireur competition={competition} serie={serie} className={styles.destination} item={x} key={index} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tireurs;
