import React, { Component, useEffect, useState } from 'react';
import Main from './Main';

class Home extends Component {
  render() {
    return (
      <>
        <Main key={1} />
      </>
    );
  }
}

export default Home;
