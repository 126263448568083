import cn from 'classnames';
import styles from '../Poste.module.sass';
import React from 'react';
import Caller from '../../../service/caller';
import config from '../../../config';

class Validation extends React.Component {
  state = {
    disabled: true,
  };

  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }

  changeState(e) {
    this.setState({
      disabled: false,
    });
  }

  render() {
    return (
      <div style={{ padding: '30px', textAlign: 'left' }}>
        {this.props.validate ? (
          <div>
            <a className={cn('button button-small', styles.button)} onClick={this.changeState}>
              Validation tireur
            </a>
            <a
              className={cn(`button button-small button-white ${this.state.disabled ? 'disabled' : ''}`, styles.button)}
              style={{
                marginLeft: '10px',
                backgroundColor: this.state.disabled ? 'lightgrey' : 'white',
                border: '1px solid lightgrey',
              }}
              onClick={() => {
                let posteds = JSON.parse(localStorage.getItem('posteds')) || [];
                posteds.map((item) => {
                  if (item.index == this.props.volee) {
                    item.posted.push(this.props.poste);
                  }
                });
                localStorage.setItem('posteds', JSON.stringify(posteds));
                Caller.request(
                  'PUT',
                  `${config.api_path}/competition/score/${this.props.competition._id}`,
                  {
                    licensee: this.props.poste.id,
                    volley: this.props.volee,
                  },
                  {
                    'x-access-token': localStorage.getItem('arbitre-token'),
                  },
                ).then((response) => {
                  window.location.href = '/poste-tireur';
                });
              }}
            >
              Validation arbitre
            </a>
          </div>
        ) : (
          ''
        )}
        {!this.props.validate ? (
          <div style={{ marginRight: '300px' }}>
            <a
              style={{ color: 'red', border: '1px solid red', float: 'right', marginRight: '50px', marginTop: '50px' }}
              className={cn(`button button-small button-white`, styles.button)}
              onClick={() => {
                window.location.href = '/incident';
              }}
            >
              Traiter incidents
            </a>
            {['ABS', 'DSQ', 'DNF', 'HM', 'OK'].map((item) => {
              return (
                <a
                  style={{
                    color: 'black',
                    border: '1px solid grey',
                    float: 'left',
                    marginRight: '5px',
                    marginTop: '50px',
                  }}
                  onClick={async () => {
                    await Caller.request(
                      'PUT',
                      `${config.api_path}/competition/licensee/${this.props.competition._id}`,
                      {
                        licensee: this.props.poste.id,
                        status: item,
                      },
                      {
                        'x-access-token': localStorage.getItem('arbitre-token'),
                      },
                    );
                    window.location.href = '/poste-tireur';
                  }}
                  className={cn(`button button-small button-white`, styles.button)}
                  key={item}
                >
                  {item}
                </a>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Validation;
